<template>
    <v-badge
        bordered
        color="light-blue lighten-3"
        icon="mdi-lock"
        overlap
        :value="!!wdRecord.freezed && !editable"
    >
        <v-card
            height="350"
            width="300"
            color="grey lighten-1"
            rounded="lg"
        >
            <v-toolbar
                height="210"
                flat
            >
                <v-container fluid class="pt-12">
                <v-row>
                    <div class="text-subtitle-2">
                    Ingresso
                    </div>
                    <v-spacer />
                    <v-btn text x-small color="red" :disabled="undoClockInButtonDisable" @click="emitUndoEvent" v-if="!editable">
                    Annulla
                    </v-btn>
                </v-row>
                <v-row>
                    <v-sheet
                    height="80"
                    width="100%"
                    rounded="xl"
                    color="grey lighten-4"
                    class="d-flex justify-center align-center"
                    >
                    <div class="text-h3">
                        {{displayClockInTime}}
                    </div>
                    </v-sheet>
                </v-row>
                <v-row class="mt-4">
                    <div class="text-subtitle-2">
                    Uscita
                    </div>
                    <v-spacer />
                    <v-btn text x-small color="red" :disabled="undoClockOutButtonDisable" @click="emitUndoEvent" v-if="displayClockOutTime && !editable">
                    Annulla
                    </v-btn>
                </v-row>
                <v-row>
                    <v-sheet
                    height="80"
                    width="100%"
                    rounded="xl"
                    color="grey lighten-4"
                    class="d-flex justify-center align-center"
                    >
                    <div class="text-h3">
                        {{displayClockOutTime}}
                    </div>
                    </v-sheet>
                </v-row>
                </v-container>
            
                <template v-slot:extension>
                <v-btn
                    fab
                    right
                    absolute
                    bottom
                    style="z-index: 2;"
                    color="amber darken-2"
                    dark
                    v-if="editable"
                    @click="emitEditEvent"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                </template>
                
            </v-toolbar>
            <v-card-text class="pa-2">
                <v-container fluid v-if="showNotes">
                    <v-row no-gutters dense>
                        <v-col cols="7" class="d-flex align-start justify-start">
                            <div style="max-width: 200px; max-height: 50px; overflow: hidden;" class="text-wrap">
                                
                                {{wdRecord.note}}
                            </div>
                        </v-col>
                        <v-col cols="5" class="d-flex align-end justify-end">
                            <v-btn 
                                small 
                                rounded 
                                class="mt-8"
                                @click="emitEditNoteEvent"
                            >
                                <v-icon left small>
                                    mdi-text
                                </v-icon>
                                Note 
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-badge>
</template>
<script>
import { DateTime } from 'luxon'

export default {
    data: function() {
        return {
            editedNote: '',
            editingNote: false,
        }
    },
    props: {
        // clockRecord: {
        //     type: Object,
        //     required: true
        // },
        wdRecord: {
            type: Object,
            required: true
        },
        clockObject: Object,
        editable: {
            type: Boolean,
            default: false
        },
        // readOnly: {
        //     type: Boolean,
        //     default: false
        // },
        showNotes: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        emitUndoEvent: function() {
            this.$emit('undo-clock-in-out');
        },
        emitEditNoteEvent: function() {
            this.$emit('edit-record-note');
        },
        emitEditEvent: function() {
            if(this.editable) {
                this.$emit('edit-record-times');
            }
        }
    },
    computed: {
        clockRecord: function() {
            return this.wdRecord.clockRecord;
        },
        wrappedClockIn: function() {
            let clockInTime = this.clockRecord.punchInTime;
            if (clockInTime) {
                return DateTime.fromISO(clockInTime);
            }
            return null;
        },
        wrappedClockOut: function() {
            let clockOutTime = this.clockRecord.punchOutTime;
            if (clockOutTime) {
                return DateTime.fromISO(clockOutTime);
            }
            return null;
        },
        displayClockInTime: function() {
            if (this.wrappedClockIn) {
                return this.wrappedClockIn.toLocaleString(DateTime.TIME_24_SIMPLE)
            }
            return null;
        },
        displayClockOutTime: function() {
            if (this.wrappedClockOut) {
                return this.wrappedClockOut.toLocaleString(DateTime.TIME_24_SIMPLE)
            }
            return null;
        },
        undoClockInButtonDisable: function() {
            if(this.wrappedClockIn)
                return !!this.wdRecord.freezed || !!this.wrappedClockOut || (this.clockObject || DateTime.local()).diff(this.wrappedClockIn, "seconds").seconds > 5*60;
            else
                return true;
        },
        undoClockOutButtonDisable: function() {
            if(this.wrappedClockOut)
                return !!this.wdRecord.freezed || (this.clockObject || DateTime.local()).diff(this.wrappedClockOut, "seconds").seconds > 5*60;
            else
                return true;
        },
    },
}
</script>